import { usePage } from "@inertiajs/vue3";

export const usePolicies = () => {
    const permissions = usePage().props.appPermissions;

    const can = (permission) => {
        if (!Object.keys(permissions).includes(permission)) {
            console.error(
                `[usePolicies] Permission ${permission} was not found.`
            );
        }
        return Boolean(permissions[permission]);
    };

    const cannot = (permission) => {
        return !can(permission);
    };

    return {
        can,
        cannot,
        permissions,
    };
};

/**
 * @param {String} permission Capability to check
 * @returns bool
 */
export function can(permission) {
    return usePolicies().can(permission);
}

/**
 * @param {String} permission Capability to check
 * @returns bool
 */
export function cannot(permission) {
    return usePolicies().cannot(permission);
}
